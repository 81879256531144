import { usePageTitle } from '@hooks/usePageTitle';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { Header } from '@layouts/Base/components/Header';
import { buildRoutePath } from '@navigation/buildRoutePath';
import { Routes } from '@navigation/routes';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { useNsiSyncControllerGetSyncDataLogQuery } from '@api/admin/adminApi';
import indents from '@styles/indents.module.scss';
import { DateService } from '@services/DateService';
import { canNavigateBack } from '@navigation/canNavigateBack';
import styles from './styles.module.scss';

export const NsiLog = () => {
  usePageTitle('НСИ Лог');

  const navigate = useNavigate();
  const backRoute = buildRoutePath(Routes.nsiLogs, { absolute: true });
  const { id } = useParams<{ id: string }>();
  const { data, isError, isLoading } = useNsiSyncControllerGetSyncDataLogQuery({ id: id ?? '' }, { skip: !id });

  useEffect(() => {
    if (!id || isError) {
      navigate(backRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isError]);

  const statusText = useMemo(() => {
    if (!data) {
      return '';
    }

    try {
      if (Number(data.chunks?.length) > 0 && data.chunks) {
        const mapped = data.chunks.map((ch) => {
          const parsedData = JSON.parse(ch.rawData);
          return `${ch.operationType}:${ch.entityName} (${parsedData.id}) - ${ch.statusText}`;
        });

        return mapped.join('\n');
      }

      return data?.statusText || '';
    } catch {
      return '';
    }
  }, [id, data]);

  const goBack = () => {
    navigate(canNavigateBack() ? -1 : (backRoute as any));
  };

  return (
    <Grid container direction="column" gap="24px">
      <Grid item>
        <Header title="НСИ Лог" backBtnTitle="Назад к списку логов" onBack={goBack} />
      </Grid>
      <Grid item>
        <Paper sx={{ padding: '20px' }}>
          {isLoading || !data ? (
            <Box display="flex" justifyContent="center" height={360} alignItems="center">
              <CircularProgress className={styles.loader} />
            </Box>
          ) : (
            <Grid container flexDirection="column" gap={indents.s}>
              <Grid item>
                <Box display="flex" alignItems="center" gap={indents.xs}>
                  <Typography>Тип операции:</Typography>
                  <Typography variant="body2">{data.operationType}</Typography>
                </Box>
              </Grid>
              <Grid item container flexDirection="row" gap={indents.s}>
                <Box display="flex" alignItems="center" gap={indents.xs}>
                  <Typography>Дата создания:</Typography>
                  <Typography variant="body2">{DateService.getLocalDate(data.createdAt)}</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={indents.xs}>
                  <Typography>Дата обработки:</Typography>
                  <Typography variant="body2">
                    {data.processDate ? DateService.getLocalDate(data.processDate) : '-'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center" gap={indents.xs}>
                  <Typography>Результат:</Typography>
                  <Typography variant="body2">
                    <pre>{statusText}</pre>
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Typography>Полученные данные:</Typography>
                <pre className={styles['raw-data']}>{JSON.stringify(data.rawData, null, 4)}</pre>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
