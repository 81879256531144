import { useEffect, useMemo, useRef, useState } from 'react';
import { useNsiSyncControllerFilterSyncDataLogsMutation } from '@api/admin/adminApi';
import { useQueryParams } from '@hooks/useQueryParams';
import {
  NsiOperationType,
  NsiSyncControllerFilterSyncDataLogsApiArg,
  NsiSyncControllerFilterSyncDataLogsApiResponse,
  SyncDataResponseDto,
} from '@api/admin/adminGeneratedApi';
import { DateService } from '@services/DateService';

export type FilterNsiLogsCacheItem = {
  response: Required<NsiSyncControllerFilterSyncDataLogsApiResponse>;
  expiresAt: number;
};

const DEFAULT_PAGE_SIZE = 50;
const CACHE_MAX_SIZE = 20;
const CACHE_LIFE = 2 * 60 * 1000;

const cache = new Map<string, FilterNsiLogsCacheItem>();

const generateCacheKey = (skip: number, size: number, filterKey: string) => {
  return `${skip}|${size}${filterKey ? `|${filterKey}` : ''}`;
};

const setCacheValue = (key: string, response: FilterNsiLogsCacheItem['response']) => {
  cache.set(key, { response, expiresAt: Date.now() + CACHE_LIFE });

  if (cache.size > CACHE_MAX_SIZE) {
    let oldest: { key: string; expiresAt: number } | null = null;

    for (const [key, value] of cache.entries()) {
      if (!oldest || oldest.expiresAt > value.expiresAt) {
        oldest = { key, expiresAt: value.expiresAt };
      }
    }

    cache.delete(oldest?.key ?? '');
  }

  return cache.get(key);
};

const getApiArgFromParams = (params: { [key: string]: string }): NsiSyncControllerFilterSyncDataLogsApiArg => {
  const operationType = params.operationType
    ? (decodeURIComponent(params.operationType).split(',') as NsiOperationType[])
    : undefined;
  const size = +params.size || DEFAULT_PAGE_SIZE;

  return {
    skip: (+params.page - 1 || 0) * Number(size),
    size,
    syncDataFilterRequestDto: {
      operationType,
      dateFrom: DateService.getDateFromParams(params.dateFrom, DateService.dateFormats.dateISOWithoutTime),
      dateTo: DateService.getDateFromParams(params.dateTo, DateService.dateFormats.dateISOWithoutTime),
      search: params.search,
    },
  };
};

const getApiArgFromParamsWithKey = (
  params: { [key: string]: string },
  filterKey = '',
): [NsiSyncControllerFilterSyncDataLogsApiArg, string] => {
  const args = getApiArgFromParams(params);
  return [args, generateCacheKey(args.skip ?? 0, args.size ?? 0, filterKey)];
};

export const useFilterNsiLogs = () => {
  const { params, setParams } = useQueryParams();
  const [[logs, count], setLogsAndCount] = useState<[SyncDataResponseDto[], number]>([[], 0]);
  const [fetch, { data, isLoading, isSuccess, isError }] = useNsiSyncControllerFilterSyncDataLogsMutation();

  const filterKey = useMemo(() => {
    return [params.operationType, params.search, params.dateFrom, params.dateTo].filter(Boolean).join('|');
  }, [params.operationType, params.search, params.dateFrom, params.dateTo]);
  const prevFilterKey = useRef(filterKey);

  useEffect(() => {
    const [, key] = getApiArgFromParamsWithKey(params, filterKey);
    const cached_response = cache.get(key)?.response;
    const response: Required<NsiSyncControllerFilterSyncDataLogsApiResponse> = {
      data: data?.data ?? cached_response?.data ?? [],
      paginate: { totalCount: data?.paginate?.totalCount ?? cached_response?.paginate.totalCount ?? 0 },
    };

    setCacheValue(key, response);
    setLogsAndCount([response.data, response.paginate.totalCount]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data, data?.paginate?.totalCount]);
  useEffect(() => {
    const [args, key] = getApiArgFromParamsWithKey(params, filterKey);
    const cachedLogs = cache.get(key);
    let refetch = !cachedLogs || !cachedLogs?.response?.data?.length || cachedLogs.expiresAt < Date.now();

    if (refetch) {
      cache.delete(key);
      fetch(args);
    } else {
      setLogsAndCount([cachedLogs!.response.data, cachedLogs!.response.paginate.totalCount]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.size, params.page]);
  useEffect(() => {
    if (prevFilterKey.current === filterKey) return;

    prevFilterKey.current = filterKey;
    if (params.page === '1') {
      fetch(getApiArgFromParams(params));
    }

    setParams({ ...params, page: '1' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey, params.size, params.page]);

  return {
    logs,
    count,
    isLoading,
    params,
    setParams,
    isFetched: !logs.length && (isError || isSuccess),
  };
};
