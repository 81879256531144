import { useNavigate } from 'react-router';
import { SyncDataResponseDto } from '@api/admin/adminGeneratedApi';
import { Table } from '@components/Table/Table';
import { columns } from '@area/nsi/components/NsiTable/columns';
import { useFilterNsiLogs } from '@area/nsi/hooks/useFilterNsiLogs';

export interface NsiTableProps {
  className?: string;
}

export const NsiTable = ({ className }: NsiTableProps) => {
  const navigate = useNavigate();
  const onRowClick = ({ id }: SyncDataResponseDto) => navigate(id);
  const { logs, count, params, setParams, isLoading, isFetched } = useFilterNsiLogs();

  return (
    <Table<SyncDataResponseDto>
      className={className}
      tableLayout="fixed"
      showPagination
      paginationProps={{
        count,
        labelRowsPerPage: 'Количество записей на странице',
        page: +params?.page - 1 || 0,
        rowsPerPage: +params?.size || 50,
        onPageChange: (_, newPage) => setParams({ ...params, page: `${newPage + 1}` }),
        onRowsPerPageChange: (e) => setParams({ ...params, size: e.target.value }),
      }}
      columns={columns}
      data={logs}
      loading={isLoading}
      fetched={isFetched}
      onRowClick={onRowClick}
    />
  );
};
