import { LocalStorageKeys } from '@area/localstorage/constants';
import {
  getLocalStorageObject,
  removeLocalStorageObject,
  setLocalStorageObject,
} from '@area/localstorage/localStorage';
import { defaultLang } from '@area/user/constants/defaultLang';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { HttpStatusCode } from 'axios';
import queryString from 'query-string';
import { normalizeMessage, getMessagesFromValidationError } from '../normalizeMessage';
import { ApiTags } from './tags';

const mutex = new Mutex();

export const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  timeout: 15 * 60 * 1000,
  paramsSerializer: (params) => {
    return queryString.stringify(params, {
      arrayFormat: 'none',
    });
  },
  prepareHeaders: (headers) => {
    const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
    if (token) {
      headers.set('Authorization', `Bearer ${token.accessToken}`);
    }
    const userLang = getLocalStorageObject(LocalStorageKeys.UserLang) ?? defaultLang;
    headers.set('accept-language', userLang);
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === HttpStatusCode.Unauthorized) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
      try {
        const refreshResult = await baseQuery(
          {
            url: '/api/auth/refresh',
            method: 'POST',
            body: { token: token?.accessToken },
          },
          api,
          extraOptions,
        );
        if (refreshResult.data) {
          setLocalStorageObject(LocalStorageKeys.AuthToken, refreshResult.data);
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          removeLocalStorageObject(LocalStorageKeys.AuthToken);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('err', err);
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  const isValidJSON = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  if (result.error?.data) {
    const data = result.error?.data as any;
    if (typeof data === 'object' && data?.errors?.length) {
      data.messages = getMessagesFromValidationError(data.errors);
    } else if (typeof data === 'object' && data?.message) {
      data.message = isValidJSON(data.message)
        ? normalizeMessage(data.message)
        : normalizeMessage(JSON.stringify(data.message)); //Sometimes we get JSON instead of plain text.
    }
  }

  return result;
};

export const adminBaseApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  reducerPath: 'kampusAdminApi',
  tagTypes: [
    ApiTags.GetUser,
    ApiTags.GetUserProfile,
    ApiTags.GetAuthors,
    ApiTags.GetPublicationTags,
    ApiTags.GetNews,
    ApiTags.GetEvent,
    ApiTags.GetInfoPage,
    ApiTags.GetPartner,
    ApiTags.GetPayments,
    ApiTags.GetNotifications,
    ApiTags.GetParticipant,
    ApiTags.GetHumanTicketsAndMailing,
    ApiTags.GetParticipant,
    ApiTags.GetFooterLinks,
  ],
});
